// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-500-html-tsx": () => import("../src/pages/500.html.tsx" /* webpackChunkName: "component---src-pages-500-html-tsx" */),
  "component---src-pages-huts-hut-1-index-tsx": () => import("../src/pages/huts/hut-1/index.tsx" /* webpackChunkName: "component---src-pages-huts-hut-1-index-tsx" */),
  "component---src-pages-huts-hut-2-index-tsx": () => import("../src/pages/huts/hut-2/index.tsx" /* webpackChunkName: "component---src-pages-huts-hut-2-index-tsx" */),
  "component---src-pages-huts-hut-3-index-tsx": () => import("../src/pages/huts/hut-3/index.tsx" /* webpackChunkName: "component---src-pages-huts-hut-3-index-tsx" */),
  "component---src-pages-huts-hut-4-index-tsx": () => import("../src/pages/huts/hut-4/index.tsx" /* webpackChunkName: "component---src-pages-huts-hut-4-index-tsx" */),
  "component---src-pages-huts-hut-5-index-tsx": () => import("../src/pages/huts/hut-5/index.tsx" /* webpackChunkName: "component---src-pages-huts-hut-5-index-tsx" */),
  "component---src-pages-huts-hut-6-index-tsx": () => import("../src/pages/huts/hut-6/index.tsx" /* webpackChunkName: "component---src-pages-huts-hut-6-index-tsx" */),
  "component---src-pages-index-tsx": () => import("../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-map-tsx": () => import("../src/pages/map.tsx" /* webpackChunkName: "component---src-pages-map-tsx" */)
}

